.google-maps-autocomplete{
    .form-label{
        color:#C3C3C3;
        &.required::before{
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: "*";
        }
    }
}