.group-contact-component{
    padding: 3em;
    
    @media (max-width: 767px) {
        padding: 3em 0;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 3em 0;
    }

    label{
        color: #C6C6C6 !important;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    input,
    select{
        width: 100%;
    }

    .release-days,
    .rooms-rates,
    .occupancy-rates{
        margin-left: 5px;
    }

 
    .actions{
        text-align: right;
        button{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.08px;
        }
    }
    
}

//Está fuera porque el Select de AntDesign los saca fuera del div del componente
.hotel-city-name{
    display: block;
}



