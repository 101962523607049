.contact-page{
    padding: 2em 2em 5em 2em;
    background-color: white;

    .title{
        color: #262626;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .sub-title{
        color: #262626;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }
}