@import '../../../styles/colors';

.private-menu{

    .ant-menu-title-content{
        color: #262626;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .menu{
        padding-top: 10px;
    }
    
    .app-icon{
        svg{
            font-size: 18px;
            path{
                fill: #707070;
            }
        }
    }
    
    .ant-menu-submenu-title{
        &:hover{
            background-color: transparent !important;
        }
    }
    
    .ant-menu-submenu,
    .ant-menu-item{
    
        margin-inline: 0 !important;
        width: 100% !important;
    
        &:hover{
            background-color: #F2F1F2;
            border-radius: 0px !important;
            border-right-width: 2px;
            border: solid 3px;
            border-width: 0 3px 0 0;
            border-color: $primary-client-color;
    
            .ant-menu-submenu-arrow{
                color: $primary-client-color !important;
            }
        
            .ant-menu-title-content{
                color: $primary-client-color !important;
            }
    
            .app-icon{
                path{
                    fill: $primary-client-color !important;
                }
            }
        }
    }
    
    .ant-menu-submenu-selected,
    .ant-menu-item-selected{
        background-color: #F2F1F2;
        border-radius: 0px !important;
        border-right-width: 2px;
        border: solid 3px;
        border-width: 0 3px 0 0;
        border-color: $primary-client-color;
    
        .ant-menu-submenu-arrow{
            color: $primary-client-color !important;
        }
    
        .ant-menu-title-content{
            color: $primary-client-color !important;
        }
        .app-icon{
            path{
                fill: $primary-client-color !important;
            }
        }
        
    }
}