
@import "../../../styles/theme";

.refresh-cache{
    // Posicionamos el div completo en el centro, flotando, como si fuese una modal
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    justify-content: center;
    align-items: center;
    display: flex;
    
    .container{
        padding: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 40%;
        background-color: white;
        border-radius: $app-border-radius;

        p{
            text-align: center;
            small{
                margin-top: 0.5em;
                display: block;
            }
        }

        .actions{
            button{
                min-width: 100px;
                margin: 0 0.5em;
            }
        }
    }
}