.loading-data-component{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 3em;
    font-family: 'Montserrat';

    p{
        margin-top: 2em;
    }
}