.cart-component{
    position: sticky;
    top: 0; // O la posición que desees

    .go-confirm{
        margin-bottom: 10px;
    }

    .other-actions{
        display: flex;
        gap: 10px;
        justify-content: space-between;
        flex-wrap: wrap;

        & > * {
            width: calc(50% - 5px);
        }
    }

    

}