.app-debugger-console .debugger-header {
    user-select: none;
  }

.app-debugger-button{
    right: 40px;
    bottom: 122px;
}

.app-debugger-console {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100vw;
    background-color: #000;
    font-family: 'Courier New', monospace;
    box-sizing: border-box;
    z-index: 15;
    min-height: 100px; 

    
    &.hidden {
        display: none;
        visibility: hidden;
    }

    .debugger-header {
        position: sticky;
        min-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        color: white;
        background-color: rgb(39, 39, 39);
        overflow-x: auto;

        h2 {
            font-size: 16px;
            margin-bottom: 8px;
            padding-left: 10px;
        }

        .debugger-header-info{
            display: flex;
            .filter{
                background-color: transparent;
            }

            .screen-resolution-icon{
                margin-right: 10px;
            }
    
            button{
                margin-right: 10px;
                background-color: rgb(224, 224, 224);
                color: rgb(39, 39, 39);
                border: none;
                height: 30px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        
    }

    .welcome-message{
        color: #fff;
        padding: 5px 20px 0 20px;
        font-size: 14px;
    }

    .log-container{
        list-style: none;
        padding: 0 20px 0 50px;
        max-height: 100%;
        overflow: auto;
        margin-top: 0;
    
        /* Estilos de la barra de desplazamiento vertical */
        &::-webkit-scrollbar {
            width: 6px; /* Ancho de la barra de desplazamiento */
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888; /* Color de la barra de desplazamiento */
            border-radius: 6px; /* Borde redondeado de la barra de desplazamiento */
        }

        /* Estilos de la barra de desplazamiento horizontal */
        &::-webkit-scrollbar-track {
            background-color: #000; /* Color de fondo de la barra de desplazamiento */
        }

        &::-webkit-scrollbar-corner {
            background-color: #000; /* Color de fondo de la esquina entre las barras */
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Cambio de color al pasar el ratón sobre la barra de desplazamiento */
        }
        
        li {
    
            margin-bottom: 12px;
    
            // Estilos para los diferentes tipos de mensajes
            &.log {
                color: #fff; // Cambiado a blanco para mensajes de log
            }
    
            &.warn {
                color: #ffb300; // Amarillo/anaranjado para mensajes de warn
            }
    
            &.error {
                color: #f00; // Rojo para mensajes de error
            }

            .log-line{
                display: flex;
                align-items: flex-start;
                gap: 10px;
                .icon-container{
                    min-width: 15px;
                    .copy-icon{
                        color: white;
                        cursor: pointer;
                    }
                }
                
            }
        }

        li:last-child {
            margin-bottom: 10em;
        }
    }

}