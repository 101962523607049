@import '../../../styles/theme';

.users-list{
    padding-bottom: 3em;

    .app-table{
        margin-top: 10px;
    }

    .total-records{
        width: 100%;

        .pagination{
            margin-top: 1em;
            text-align: right;
        }
        .counter{
            margin-top: 1em;
            text-align: left;
            color: $primary-client-color;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }

    table{
        margin-top: .5em;
        .ant-table-thead{
            color: #212331;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
        }

        .ant-table-cell{
            color: #262626;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }
    

    .user-actions{
        display: flex;
        align-items: center;
        gap: 10px;
        .icon{
            font-size: 1.35em;
        }
    }

    .app-list{
        margin-top: 20px;
        border: 1px solid #DDD;

        .user-list-item{
            padding-bottom: 0px;
        }

       .list-item{
            padding: 10px;

            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 5px;

            .user-name{
                padding-left: 15px;
            }


            &.flex-center{
                align-items: center;
                justify-content: center;
            }

            strong{
                color: rgba(33, 35, 49, 0.80);
                font-family: Roboto;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.2px;
            }

            span{
                color: rgba(38, 38, 38, 0.80);
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.2px;
                text-align: left;
            }

            &.fiscal-id{
                align-items: center;
            }

            .additional-info {
                min-width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .user-name{
                color: #262626;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.2px;
                text-align: left;
            }

       }

       
    
        .list-additional-info{
            background: #FAFAFA;
            border-top: 1px solid #DDD;
            border-bottom: 1px solid #DDD;
            padding-left: 15px;
            padding-right: 15px;
        }
        
    }

}