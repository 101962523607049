@import '../../../styles/theme';

@media print {

    .booking-ok-message{
        display: none;
        visibility: hidden;
        height: 1px;
        overflow: hidden;
    }

    .ant-layout{
        background: white !important;
    }

    .content-page{
        max-height: fit-content !important;
    }
    
    .private-header-component,
    .private-footer-component,
    .ant-layout-sider{
        display: none !important;
        visibility: hidden !important;
        overflow: hidden;

    }
    .print-block{
        margin-top: 30px;
        page-break-before: always !important;
    }

    @page {
        size: auto; /* Puede configurarse en A4 u otro tamaño específico */
        margin: 0; /* Asegúrate de que los márgenes sean apropiados */
      }
      

    .icon-cancelled-at,
    .agency-more-info,
    .agency-more-info-content,
    .btn-actions{
        display: none !important;
    }

    .text-cancelled-at{
        display: block !important;
    }
    
    .occupation-list,
    .cancellation-policies-list{
        visibility: visible !important;
        display: block !important;
    }

    .total-price{
        margin-top: 20px;
    }

}



.booking-ok-customer-voucher-page{
    padding: 0 80px 6em 80px;
    
    .booking-ok-message{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .app-icon{
            margin-top: 25px;
            //zoom: 1.2;
        }

        h1{
            color: $primary-client-color;
            margin: 17px 0 0 0;
            font-family: "Montserrat";
        }

        h2{
            color: $secondary-client-color;
            margin: 0 0 45px;
            font-family: "Montserrat";
        }
    }

    .text-cancelled-at{
        display: none;
        margin-top: 5px;
        font-size: 12px;
    }

    .resume{

        .icon-cancelled-at{
            margin-left: 10px;
        }

        .hotel-name{
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
        }

        .hotel-address, .hotel-phone{
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 158.824% */
            margin: 0;
        }


        .booking-header-resume{
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; 
            margin-top: 10px;
            margin-bottom: 15px;
            
            span{
                margin-right: 10px;
            }
        }

        .btn-actions{
            display: flex;
            justify-content: flex-end;
            padding-bottom: 0.5em;
            

            button{
                margin-left: 10px;
                border: none;
            }
        }

        .alerts{
            text-align: right;
            .left-time-to-enable-send-button{
                font-family: Montserrat;
                width: 100%;
                border: 0;
                font-size: 12px;
                margin-top: 3px;
            }
            padding-bottom: 3em;

        }
    }

    .resume-link{
        border-bottom: 1px dashed $primary-client-color;
        cursor: pointer;
        color: $primary-client-color;
    }

    .resume, .rooms, .services, .total-resume{
        margin-bottom: 10px;
        border-radius: 2px;
        background: white;

        h4{
            margin-top: 0px;
            margin-bottom: 5px;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px; /* 161.111% */
        }

        .regime-name{
            margin-top: -10px;
            margin-bottom: 10px;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 29px;

        }

        .main-title{
            border-left: solid 2px $primary-client-color;
            padding: 5px 10px;
            margin-bottom: 30px;

            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }

        .content{
            padding-left: 70px;
            padding-right: 70px;

            @media (max-width: 767px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        
            @media (min-width: 768px) and (max-width: 1023px) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    .rooms{
        .room{
            padding-bottom: 10px;
            margin-bottom: 15px;

            &:not(:last-child) {
                border-bottom: solid 1px #b8b8b8;
            }

            .room-details{
                padding-left: 30px;
            }

            .room-image{
                background-color: rgba(190, 188, 188, 0.397);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 260px;
                height: 136px;
            
                @media (min-width: 0px) and (max-width: 1023px) {
                    margin-bottom: 15px;
                }
            }


            .occupation,
            .cancellation-policies{
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                cursor: pointer;
                display: flex;
                align-items: center;

                .plus-icon{
                    fill: $primary-client-color;
                    margin-right: 5px;
                }
            }

            .cancellation-policies-list,
            .occupation-list{
                list-style: none;
                padding: 0 25px;

                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                li{
                    margin-bottom: 5px;
                }

                &.hidden{
                    display: none;
                }

            }

            .room-price{
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px; /* 161.111% */

                @media (min-width: 0px) and (max-width: 1023px) {
                    text-align: right;
                    padding-right: 20px;
                }
            }
            
        }
    }

    .services{
        .service{
            padding-bottom: 10px;
            margin-bottom: 15px;


            &:not(:last-child) {
                border-bottom: solid 1px #b8b8b8;
            }

            .image{
                background-color: rgba(190, 188, 188, 0.397);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 260px;
                height: 136px;

                @media (min-width: 0px) and (max-width: 1023px) {
                    margin-bottom: 15px;
                }
            }

            .description{
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 30px;

                h4{
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-bottom: 0;
                }
            }

            .service-price{
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px; /* 161.111% */
                
                @media (min-width: 0px) and (max-width: 1023px) {
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }
    }

    .total-resume{
        padding-bottom: 2em;

        .total-price{
            margin-bottom: 20px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: right;
            font-size: 1.8em;
        }

        .agency-more-info{
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            cursor: pointer;
            display: flex;
            align-items: center;

            .plus-icon{
                fill: $primary-client-color;
                margin-right: 5px;
            }
        }


        .agency-more-info-content{
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-left: 30px;

            &.hidden{
                display: none;
            }
        }
    }

    .owner-data{
        .owner-name,
        .owner-phone,
        .owner-email{
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 0px;
            margin-bottom: 5px;
        }
    }
    
    .background-image-resume{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 206.359px;
    }

    .room-occupancy-resume{
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 29px;
    }

    .cancelation-policy{
        span{
            margin: 0 5px;
        }
    }
}