@import "../../../styles/theme";

.bookings-list{
    padding-bottom: 3em;

    .tag-status{
        min-width: 100px;
        text-align: center;
    }

    .icon-cancelled-at,
    .icon{
        margin-left: 5px;
    }

    .total-records{
        width: 100%;

        .pagination{
            margin-top: 1em;
            text-align: right;
        }
        .counter{
            margin-top: 1em;
            text-align: left;
            color: $primary-client-color;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }

    table{
        margin-top: 1.5em;
        .ant-table-thead{
            color: #212331;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }

    .bookings-resume{
        color: #262626;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;

        span{
            font-weight: 600;
        }
    }

    .locator-data{
        .locator{
            color: #262626;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
        }

        .creation-date, .status{
            color: #262626;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
        }

        .status{
            margin-top: 0.5em;
            display: flex;
            gap: 5px;

            .icon{
                cursor: pointer;
            }
        }
    }

    .hotel-data{
        .hotel-name{
            color: #262626;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
        }

        .check-in-out{
            color: #262626;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }

    .owner-data{
        color: #262626;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .user-data{
        color: #262626;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .pricing-data {
        display: flex;
        flex-wrap: wrap; /* Permite que los elementos bajen a la siguiente línea si no caben */
        gap: 4px; /* Reduce el espacio entre las filas y columnas */
        color: #262626;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5; /* Mejora la legibilidad */
        letter-spacing: -0.2px;
        width: 100%;
    }
    
    .pricing-data > div {
        flex: 1 1 34%; /* Cada elemento ocupa un 48% del ancho, dejando espacio entre ellos */
        max-width: 34%; /* Limita el ancho máximo para mantener la proporción */
        display: flex;
        flex-direction: row; /* Mantiene los textos y valores en la misma fila */
        justify-content: flex-start; /* Alinea el contenido al inicio del contenedor */
        align-items: center; /* Alinea verticalmente los elementos */
        gap: 8px; /* Espaciado interno entre el texto y el componente monetario */
        word-break: break-word; /* Permite que las palabras largas se dividan */
        white-space: normal; /* Permite saltos de línea */
    }
    

    
    
    




    .user-actions{
        display: flex;
        align-items: center;
        gap: 10px;
        .icon{
            font-size: 1.35em;
        }
    }

    .app-list{
        margin-top: 20px;
        border: 1px solid #DDD;

        

       .list-item{
            padding: 10px;

            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 5px;

            .locator{
                padding-left: 15px;
                color: #262626;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.2px;
                text-align: left;
            }

            &.flex-center{
                align-items: center;
                justify-content: center;
            }

            strong{
                color: rgba(33, 35, 49, 0.80);
                font-family: Roboto;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.2px;
            }

            span{
                color: rgba(38, 38, 38, 0.80);
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.2px;
                text-align: left;
            }

            &.fiscal-id{
                align-items: center;
            }

            .additional-info {
                min-width: 100%;
                display: flex;
                justify-content: space-between;
            }


       }

       
    
        .list-additional-info{
            background: #FAFAFA;
            border-top: 1px solid #DDD;
            border-bottom: 1px solid #DDD;
            padding-left: 15px;
            padding-right: 15px;
        }
        
    }
}