.postponed-bookings-component{

    .show-all-less-container{
        text-align: right;

        .show-all,
        .show-less{
            color: #262626;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }

    .app-card-header{
        display: flex;
        align-items: end;
        justify-content: end;
    }

    .app-title{
        margin-bottom: 40px;
    }

    .alert-caducity{
        margin-top: 10px;
        width: 100%;
    }

    .width-24px{
        width: 24px;
        height: 24px;
        font-size: 24px;

        svg{
            width: 24px;
            height: 24px;
        
        }
    }

    

    .hotel-info,
    .locator-info{
        margin-bottom: 15px;

        .locator-number{
            margin-bottom: 10px;
        }

    }

    .booking-info{
        .expired-date{
            margin: 10px 5px;
        }
    }

    .pricing-info{
        .pricing-title{
            margin-bottom: 10px;
        }
    }

    .hotel-info,
    .booking-info,
    .locator-info,
    .pricing-info{
        display: flex;
        align-items: start;
        gap: 10px;

        div{
            span{
                display: block;
            }
        }
    }

    .actions-info{
        text-align: right;
    }
}