.booking-engine-page{
    padding: 3em 2em;
    background-color: white;

    :where(.css-dev-only-do-not-override-2q8sxy).ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon,
    :where(.css-dev-only-do-not-override-2q8sxy).ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title{
        color: var(--primary-color, blue);
        border-color: var(--primary-color, blue);
    }

    @media (max-width: 1080px) {
        padding: 1em 10px;
        transition: all .2s;
        //zoom: 0.7;
        
    }

    .alert-search-availability{
        margin: 40px 0;
    }



    .widget-container{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 20px;
    }

    .booking-container{
        margin-top: 2em;

        .booking-engine-pagination,
        .booking-engine-result,
        .booking-engine-footer{
            margin-top: 2em;
        }

        .booking-engine-steps{
            padding-right: 13px;
            margin-bottom: 50px;
        }
    }

    .cart-show-button{
        right: 60px;
        bottom: 180px;
        width: 55px;
        height: 55px;
    }

    .cart{
        margin-top: 20px;
        position: sticky;
        top: 0; 

        &.mobile{
            width: 100%;
            background-color: white;
            box-shadow: -4px 0px 12px -2px rgba(0, 0, 0, 0.21);
            position: fixed;
            z-index: 1000;
            right: 0;
            height: 100%;
            min-width: 50%;
            margin-top: 0;
            background-color: #ECECEC;
            overflow-y: scroll;
            padding: 0 !important;

            &.visible{
                visibility: visible;
                display: inline-block;
            }

            &.hidden{
                visibility: hidden;
                display: none;
            }
        }
      }
}