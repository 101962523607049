@import '../../../styles/colors';

.register-page{
    .title{
        color: #262626;
        font-family: Roboto;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        margin: 25px 0 8px 0;
        text-align: center;
    }

    .steps{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ant-steps-item-icon{
            cursor: pointer;
        }

        .step-title{
            color: #262626;
            font-family: Roboto;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
            margin-bottom: 19px;
        }

        .items{
            max-width: 60%;

            @media (max-width: 767px) {
                max-width: 95%;
            }
    
            @media (min-width: 768px) and (max-width: 1023px) {
                max-width: 80%;
            }
        }
    }

    .step-title{
        p{
            color: #262626;
            text-align: center;
            font-family: Roboto;
            font-size: 1.5em;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
                
            padding: 23px 0 0 0;
        }

        .sub-title{
            margin-bottom: 1em;
            text-align: center;
            width: 100%;
        }
    }
    
    .user-type-selector{
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding: 2em;

        @media (max-width: 767px) {
            max-width: 95%;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
            max-width: 80%;
        }


        .type-selector{
            cursor: pointer;
            border: solid 1px #D9D9D9;
            border-radius: 2px;
            display: flex;
            height: 225px;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 1em;
            margin-bottom: 1em;

            &:hover{
                transition: all .3s;
                border-color: $primary-client-color;
            }
            

            .image{
                width: 70px;
                height: 70px;
                display: flex;
                align-items: end;
                justify-content: center;
                margin-bottom: 25px;
            }

            .label{
                color: #262626;
                text-align: center;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.2px;
                height: 2.5em;
            }
        }
    }
}