.contact-page{

    .phone-container{
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .more-and-less{
        text-align: right;
    }

    .app-main-title{
        text-align: center;
    }

}