@import '../../../styles/colors';

.booking-data-form{
    padding-top: 20px;


    .copy-of-titular{
        display: flex;
        button{
            margin-left: 10px;
        }
    }

    .accept-commercial-emails{
        color: #343940;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 184.615% */
    }

    .room-title{
        color: #2F353C;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
    }

    .guest-type-title{
        color: $primary-client-color;
        font-family: Montserrat;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0;
    }

    .app-actions{
        display: flex;
        justify-content: center;
        .app-button{
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 2em;
            padding: 1.5em 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                margin-right: 10px;
            }
        }
    }
}