@import '../../../styles/theme';
.news-not-featured-list-component {

    .new-card {
        display: block;
        border-radius: $app-border-radius ; // Bordes redondeados
        cursor: pointer; // Hacer clic en todo el bloque
        background-color: #f5f5f5; // Fondo por defecto


        &:hover {
            background-color: #e8e8e8; // Fondo al pasar el cursor
        }
    }

    .img-featured {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: $app-border-radius ; // Bordes redondeados
    }

    .text-container {
        padding: 20px; // Espaciado interno
    }

    .news-paragraph {
        color: #555; // Gris más claro para los párrafos secundarios
        font-size: 16px; // Tamaño de fuente estándar
    }
   


    .pagination-container{
        display: flex;
        justify-content: left;
        margin-top: 40px;
    }
   
}
