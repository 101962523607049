@import './colors';
@import './fonts';
@import './texts';

@import './engine';

//@import './engine';


$header-height: 70px;
$foother-height: 60px;
$page-content-width: 80%;
$page-content-width-tablet: 90%;
$page-content-width-mobile: 100%;
$app-border-radius: 2px;

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Lato;
  background-color: #f6f6f6;
}

.content-page {
  scroll-behavior: smooth;
}

.private-scroll-top {
  right: 40px;
  bottom: 70px;
}

@at-root .width#{&} {
  &-40 {
    max-width: 40%;
    margin-left: 30%;
  }

  &-50 {
    max-width: 50%;
    margin-left: 25%;
  }

  &-60 {
    max-width: 60%;
    margin-left: 20%;
  }

  &-70 {
    max-width: 70%;
    margin-left: 15%;
  }

  &-80 {
    max-width: 80%;
    margin-left: 10%;
  }

  &-90 {
    max-width: 90%;
    margin-left: 5%;
  }

  &-100 {
    max-width: 100%;
    margin-left: 0;
  }
}

.page-content {
  flex: 1;

  @media (max-width: 767px) {
    transition: all .5s;
    max-width: $page-content-width-mobile;
    margin-left: calc((100% - #{$page-content-width-mobile}) / 2);
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    transition: all .5s;
    max-width: $page-content-width-tablet;
    margin-left: calc((100% - #{$page-content-width-tablet}) / 2);
  }

  &.no-margin {
    max-width: 100% !important;
    margin-left: 0 !important;
  }
}

button:disabled {
  border-radius: $app-border-radius !important;
}


@at-root .app#{&} {

  &-calendar-pvp-details{
    color: rgb(36, 155, 100);
    font-weight: 400;
    font-family: 'Montserrat';
  }

  &-collapse {
    border-radius: 2px;
    margin-top: -18px;

    .advanced-search-header {
      background-color: #F6F6F6;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }

  &-loading {
    margin-left: 5px;
    margin-right: 5px;
  }

  &-divider {

    font-family: 'Roboto';

    span {
      color: #5e5d5d;
    }
  }

  &-input {
    border-radius: $app-border-radius;
    width: 100%;

    //Para incluir los type multiples 
    .ant-select-selector {
      border-radius: $app-border-radius;
      width: 100%;
    }

  }

  &-table {

    table {

      thead {
        tr {
          th:first-child {
            border-start-start-radius: $app-border-radius !important;
          }

          th:last-child {
            border-start-end-radius: $app-border-radius !important;
          }
        }
      }
    }
  }

  &-modal {
    color: #262626;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;


    .ant-modal-content {
      border-radius: $app-border-radius;
      padding: 0;

      @media (max-width: 767px) {
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        overflow: auto;
      }

      @media (min-width: 768px) and (max-width: 1023px) {}

    }

    .ant-modal-header {
      border-bottom: 1px solid #f0f0f0;


    }

    &.no-header-boreder {
      .ant-modal-header {
        border-bottom: none;
      }
    }

    .ant-modal-header {
      padding: 20px 40px 20px 20px;
    }

    .ant-modal-body,
    .ant-modal-footer {
      padding: 20px;
    }
  }

  &-width-100 {
    width: 100%;
  }

  &-select {
    border-radius: $app-border-radius;
    width: 100%;
  }

  &-white-button {
    border-radius: $app-border-radius;

    &:hover {
      transition: all .5s;
      color: $primary-client-color !important;
      border-color: $primary-client-color !important;
    }
  }

  &-actions {
    width: 100%;
    text-align: right;

    button {
      margin-left: 10px;
    }

    &.centered {
      text-align: center;
    }
  }

  &-button {
    border-radius: $app-border-radius !important;
    background: $primary-client-color !important;
    border-color: $primary-client-color;
    color: white;


    &:hover {
      transition: all .5s;
      background: $secondary-client-color !important;
      color: white !important;
      border-color: $secondary-client-color !important;
    }

    &.danger {
      background-color: rgba(255, 0, 0, 0.589) !important;
      border-color: rgba(255, 0, 0, 0.589) !important;
      color: white !important;
      transition: all .5s;
      &:hover {
        background-color: red !important;
        border-color: red !important;
      }
    }

    &.blue-button {
      background: #1D7CE3;
      border-color: #1D7CE3;
      color: white;

      &:hover {
        background: #1D7CE3;
        color: white !important;
        border-color: #1D7CE3 !important;
      }
    }

    &.app-cart-button {
      width: 90%;
      margin-bottom: 10px;
      padding: 5px 10px;
    }

    &.full{
      width: 100%;
    }

    &.middle{
      display: inline;
      width: 50%;
    }
  }

  &-icon {
    color: $primary-client-color;
    fill: $primary-client-color;

    path{
      color: $primary-client-color;
      fill: $primary-client-color;
    }

    &-hover {
      color: $secondary-client-color;
      fill: $secondary-client-color;
    }

    &.danger {
      color: red !important;
      fill: red !important;
    }


  }

  &-colored-main-font {
    color: $primary-client-color;
  }

  &-colored-secondary-font {
    color: $secondary-client-color;
  }

  &-link {
    cursor: pointer;
    color: $primary-client-color;

    &.app-active-link {
      color: $primary-client-color;

      path{
        color: $primary-client-color;
        fill: $primary-client-color;
      }
    }

    &.no-underline {
      text-decoration: none;
    }

    &:hover:not(.no-hover),
    &:active:not(.no-hover) {
      color: $secondary-client-color;
    }

    &.no-hover:hover,
    &.no-hover:active {
      opacity: .9;
    }
  }

  &-title {
    color: #2F353C;
    font-family: Montserrat;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &::after {
      content: '';
      display: block;
      width: 10%;
      height: 2px;
      background-color: $primary-client-color;
      margin-top: 10px;
    }
  }


  &-subtitle {
    color: #0d0f10;
    font-family: Montserrat;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
    text-align: left; // Alineado a la izquierda
  }


  &-box-form {
    border: 1px solid #DDD;
    background: #FFF;
    border-radius: $app-border-radius;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;

    .ant-form-item-label {
      label {
        color: #787878;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }

  }

  &-drop-down{

    .drop-down-content{
      display: flex;
      align-items: center;
      label{
        cursor: pointer;
        span{
          margin-right: 15px;
        }
      }
    }

    button{
      border-radius: $app-border-radius !important;
      background: $primary-client-color;
      border-color: $primary-client-color;
      color: white;

      &:hover {
        transition: all .5s;
        background: $secondary-client-color;
        color: white !important;
        border-color: $secondary-client-color !important;
      }

      &.danger {
        background: white;
        color: red;
        border-color: red;

        &:hover {
          color: red !important;
          border-color: red !important;
        }
      }

      &.blue-button {
        background: #1D7CE3;
        border-color: #1D7CE3;
        color: white;

        &:hover {
          background: #1D7CE3;
          color: white !important;
          border-color: #1D7CE3 !important;
        }
      }

      &.app-cart-button {
        width: 90%;
        margin-bottom: 10px;
        padding: 5px 10px;
      }
    }
      
    button:first-child{
        flex-grow: 1;
        border-radius: $app-border-radius 0 0 $app-border-radius !important;
    }

    button:last-child{
        border-radius: 0 $app-border-radius $app-border-radius 0 !important;
    }
  }

  &-card{
    border: solid 1px;
    border-radius: $app-border-radius;
    border-color: #E8E8E8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    margin-bottom: 10px;
  }
}


.prefix-search-icon {
  color: #b8b5b5
}

// Modificaciones sobre los estilos de Ant Design

.ant-dropdown{
  ul{
    border-radius: $app-border-radius !important;
    li{
      border-radius: $app-border-radius !important;
    }
    span{
      color: $primary-client-color !important;
    }
  }
}

.ant-select-selection-item {
  small {
    //Se usa para evitar que en el desplegable de Grupos y Convenciones, al seleccionar un Hotel, aparezca el Small que contiene la ciudad a la que pertenece.
    display: none;
    visibility: hidden;
  }
}

.ant-collapse>.ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-byeoj0).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0 !important;
}

.ant-select-single .ant-select-selector,
.ant-pagination .ant-pagination-item {
  border-radius: $app-border-radius;
  border-color: #d9d9d9;
}

.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  border-color: $primary-client-color;

  a {
    color: $primary-client-color !important;
  }
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: $primary-client-color;
  border-color: $primary-client-color;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: white;
  border-color: $primary-client-color;

  svg {
    color: $primary-client-color;

  }
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: $primary-client-color;
}

.ant-collapse-header {
  &.right-align {
    flex-direction: row-reverse;
    text-align: right;
    border-radius: 0 !important;
    color: #212331;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
  }
}


.modal-content {
  z-index: 1;
  position: relative;
  padding: 20px 30px;
  max-width: 100%;
  border-bottom-left-radius: 3 * 2;
  border-bottom-right-radius: 3 * 2;
}

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  padding: 20px;
}

.modal-foreground {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-drawer .ant-drawer-body{
  @media (max-width: 767px) {
    padding: 0;
  }
}