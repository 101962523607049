.cancellation-policies-component {
    margin-bottom: 10px;

    .cancellation-policy-line {
        strong {
            font-weight: 600;
        }
    }

    .cancelation-policy {
        display: flex;
        align-items: flex-start; /* Alinea verticalmente los elementos */
        justify-content: space-between; /* Distribuye los elementos */
        gap: 8px; /* Espaciado entre elementos */
    }

    .cancelation-policy span {
        white-space: nowrap; /* Evita que el texto o componente MoneyComponent se dividan */
        overflow: hidden; /* Evita desbordamientos */
        text-overflow: ellipsis; /* Añade puntos suspensivos si el texto es demasiado largo */
    }

    @media (max-width: 1200px) {
        .cancelation-policy {
            flex-wrap: wrap; /* Permite que los elementos se envuelvan si no caben */
            justify-content: flex-start; /* Alinea los elementos al principio */
            gap: 16px; /* Aumenta el espaciado para evitar que se amontonen */
        }

        .cancelation-policy > * {
            flex: 1 1 auto; /* Permite que los elementos se adapten proporcionalmente */
            min-width: 150px; /* Asegura que haya un ancho mínimo para cada elemento */
        }
    }
}
