.news-page{
    padding: 2em;
    background-color: white;

    .title{
        color: #262626;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        padding-bottom: 10px;
        padding-top : 10px
    }

}