.users-filter{

    .prefix-search-icon{
        color: #D9D9D9;
    }

    label{
        color: #C6C6C6 !important;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    input, select{
        color: #292929;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .search-button{
        color: #FFF;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.08px;

        margin-top: 30px;
        width: 100%;

        @media (max-width: 991px) {
            margin-top: 0px;
        }
    }
    
}