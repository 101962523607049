.dashboard-page{
    padding-bottom: 3em;
    .booking-widget{
        
        h1{
            color: #262626;
            font-family: Montserrat;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.2px;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .statistics-widgets{
        margin-top: 50px;

        .image-container{
            height: 150px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 20px;
        }

        h1{
            color: #262626;
            font-family: Montserrat;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.2px;
            text-align: center;
        }
    }
}