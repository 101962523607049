.contact-phone-component{
    min-width: 263px;
    max-height: 110px;
    text-align: center;
    background-color: #fff;
    float: left;
    margin: 15px;
    padding: 12px;
    border-radius: 3px;
    box-shadow: 0 1px 6px 0 rgba(51,51,51,.2);


    .flag{
        small{
            display: block;
            margin-top: -5px;
            margin-bottom: 10px;
        }

        .flag-icon{
            width: 62px;
            margin-bottom: 10px;
            border-radius: 6px;
            
        }
    }

    .phone{
        font-weight: bolder;
    }
}