@import '../../styles/theme';

.private-page-wrapper{
   


    .ant-menu-inline-collapsed {
        width: 60px;
    }

    .private-content{
        min-height: calc(100vh - #{$header-height} - #{$foother-height});
        display: flex;

        .menu{
            border: none;
        }
    
        .ant-layout-sider {
            position: relative;
            min-width: 0;
            background: white; 
            transition: all 0.2s,background 0s;
            border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
        }

        .content-page{
            padding: 20px;
            padding: 20px;
            overflow-x: hidden;
            max-height: calc(100vh - #{$header-height} - #{$foother-height});
            overflow-y: scroll;
        }

        
    }
}