.landing-page{
    .loader-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        .dots-bars-2 {
            width: 40px;
            height: 40px;
            --c: linear-gradient(currentColor 0 0);
            --r1: radial-gradient(farthest-side at bottom,currentColor 93%,#0000);
            --r2: radial-gradient(farthest-side at top   ,currentColor 93%,#0000);
            background: 
              var(--c) ,
              var(--r1),
              var(--r2),
              var(--c) ,  
              var(--r1),
              var(--r2),
              var(--c) ,
              var(--r1),
              var(--r2);
            background-repeat: no-repeat;
            animation: db2 1s infinite alternate;
          }
          
          @keyframes db2 {
            0%,25% {
              background-size: 8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
              background-position: 0 50%,0 calc(50% - 2px),0 calc(50% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
           }
           50% {
              background-size: 8px 100%,8px 4px,8px 4px,8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
              background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
           }
           75% {
              background-size: 8px 100%,8px 4px,8px 4px,8px 100%,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
              background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(0% - 2px),50% calc(100% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
           }
           95%,100% {
              background-size: 8px 100%,8px 4px, 8px 4px,8px 100%,8px 4px,8px 4px,8px 100%,8px 4px,8px 4px;
              background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(0% - 2px),50% calc(100% + 2px),100% 50%,100% calc(0% - 2px),100% calc(100% + 2px);
           }
          }
    }
  

  
}