.verify-email{

    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: center;

    color: #262626;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;

    .info{

        color: #262626;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.2px;

        text-align: center;

        button{
            min-width: 250px;
            margin-top: 20px;
        }
    }

    .resend-email{
        margin-top: 2em;
        display: flex;
        flex-direction: column;

        small{
            color: #888;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;

            &.link-resend-email{
                cursor:pointer;
            }
        }


    }
}