.statistic-component{
    border-radius: 3px;
    border: 1px solid #DDD;
    background: #FFF;
    padding: 23px;
    margin-bottom: 20px;

    width: 100%;
    flex-shrink: 0;

    .header{
        color: #222;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.216px;
    }

    .content{
        .resume{
            display: flex;
            flex-direction: column;
            .amount{
                color: #222;
                font-family: Roboto;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.216px;
            }

            .title{
                margin-top: 10px;
                margin-bottom: 5px;
                color: #222;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.216px;
            }

            .subtitle{
                color: #666;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.216px;
            }

            .progress-bar{
                margin-top: 5px;
            }
        }
    }
}