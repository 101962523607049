.contact-form-component{
    .actions{
        width: 100%;
        text-align: center;

        button{
            width: 325px;
            height: 46px;
            flex-shrink: 0;
        }
    }

    .app-checkbox{
        margin-top: 20px;
        text-align: center !important;
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    label{
        color: #C6C6C6 !important;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    input, select{
        color: #292929;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .actions{
        button{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.08px;
        }
    }
}