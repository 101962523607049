@import '../../../styles/colors';


.agency-selector-component{
    margin-top: 1em;
    padding-bottom: 3em;

    .triangle {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-left: 35px solid transparent;
        border-bottom: 35px solid $primary-client-color;
        transform: rotate(-90deg);

        .question{
            transform: rotate(90deg);
            position: absolute;
            
            &:not(.mobile){
                margin-top: 18px;
                margin-left: -16px;
            }

            &.mobile{
                margin-top: 16px;
                margin-left: -17px;
            }
        }
      }
    

    .ant-list-item {
        border-block-end: 0px solid rgba(5, 5, 5, 0.06);
        padding: 0;
    }

    

    .is-used-icon{
        margin-left: 10px;
        color: $primary-client-color;
        float: right;
    }
    
    .advanced-search-container{
        border-radius: 2px;
        margin-top: -18px;

        .advanced-search-header{
            background-color: #F6F6F6;
        }
    }

   

    
    .agency-list{
        margin-top: 10px;

        .total-records{
            width: 100%;

            .pagination{
                margin-top: 1em;
                text-align: right;
            }
            .counter{
                margin-top: 1em;
                text-align: left;
                color: $primary-client-color;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.2px;
            }
        }

    }

    .app-list-container{
        .headers{
            border: solid;
            border-width: 1px 1px 0 1px;
            border-color: #DDD;
            .mobile-column{
                height: 64px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: left;
                background: #FAFAFA;
                color: #212331;
                font-family: Roboto;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.2px;
                padding-left: 10px;

                &:last-child{
                    justify-content: center;
                    padding-left: 0;
                }
            }
        }

        .app-list{
            border: 1px solid #DDD;
           .list-item{
                padding: 10px;
    
                display: flex;
                flex-direction: column;
                align-items: baseline;
                gap: 5px;

                &.flex-center{
                    align-items: center;
                    justify-content: center;
                }

                strong{
                    color: rgba(33, 35, 49, 0.80);
                    font-family: Roboto;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.2px;
                }

                span{
                    color: rgba(38, 38, 38, 0.80);
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.2px;
                    text-align: left;
                }

                &.fiscal-id{
                    align-items: center;
                }
    
                .additional-info {
                    min-width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                .agency-name{
                    color: #262626;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.2px;
                    text-align: left;
                }

           }
    
           
        
            .list-additional-info{
                background: #FAFAFA;
                border-top: 1px solid #DDD;
                border-bottom: 1px solid #DDD;
                padding-left: 15px;
                padding-right: 15px;
            }
            
        }
    }

    .no-records-found{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background:#f5f5f5;
        margin: 15px 0;
        min-height: 200px;
        
        *:not(strong){
            margin: 15px;
        }
    }

    .request-agency-registration{
        text-align: center;
        margin: 10px 0 20px 0;
        strong{
            color: $primary-client-color;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .actions{
        text-align: right;

        button{
            margin-left: 10px;
        }
    }
}