@import '../../../../../styles/theme';

.template-1{
    .module-0{
        .background{
           width: 100%;
           height: calc(100vh - #{$header-height});
           background-size: cover;
           background-position: center;
           background-repeat: no-repeat;
           display: flex;
           align-items: center;
           justify-content: center;
           flex-direction: column;
           position: relative; /* Agregar posición relativa */
      
           .filter-layout {
             position: absolute;
             width: 100%;
             height: 100%; 
             opacity: 0.30;
             background: #000;
             z-index: 0;
           }

           .color-layout{
                position: absolute;
                width: 70%;
                height: calc(100vh - #{$header-height} - 20px);
                opacity: 0.25;
                background: $primary-client-color;
                z-index: 1;
           }

           

           .title{
                z-index: 2;
                margin-bottom: 50px; 
                
                p:first-child{
                    text-align: center;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 62px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.08px;

                    @media (max-width: 960px){
                        font-size: 32px;
                        
                    }

                    @media (max-height: 450px){
                        font-size: 32px;
                        
                    }

                    .small{
                        font-size: 52px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.08px;
                    }
                }

                p:last-child{
                    text-align: center;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.08px;
                    opacity: 70%;

                    @media (max-height: 450px){
                        font-size: 32px;
                    }

                    @media (max-width: 960px){
                        font-size: 32px;
                    }
                }
           }


           .actions{
                z-index: 2;
                display: flex;
                gap: 20px;
                align-items: center;
                justify-content: center;

                @media (max-width: 767px) {
                    flex-direction: column;

                    .go-register,
                    .go-login{
                        margin-bottom: 20px;
                    }
                }

                .go-register,
                .go-login{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 250px;
                    max-width: 100%;
                    height: 55px;
                    flex-shrink: 0;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.08px;

                    text-decoration: none;
                    border-radius: 3px;
                }

                .go-register{
                        border-color: $primary-client-color;
                        background-color: $primary-client-color;
                }

                .go-login{
                        border-color: $secondary-client-color;
                        background-color: $secondary-client-color;;
                }
           }
        }
    }

    .module-1{
        .grid-row{
            width: 100%;
            margin: 0 !important;

            .grid-column{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 !important;
                margin:0;
                .image-container{
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 100%;
                    height: 100%;

                    @media (max-width: 768px) {
                        min-height: 200px;  /* Ajusta el valor según sea necesario para dispositivos pequeños o móviles */
                    }
                }

                &.text{
                    font-family: Montserrat;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.08px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    min-height: 600px;

                    @media (max-width: 1200px) {
                        min-height: 350px;  /* Ajusta el valor según sea necesario para dispositivos medianos */
                    }
                
                    @media (max-width: 768px) {
                        min-height: 200px;  /* Ajusta el valor según sea necesario para dispositivos pequeños o móviles */
                    }
                
                    @media (max-width: 480px) {
                        min-height: 100px;  /* Ajusta el valor según sea necesario para dispositivos más pequeños */
                    }

                    p{
                        padding: 0 60px;

                        .max{
                            display: inline;
                            font-weight: bold;
                            font-family: Montserrat;
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.08px;
                        }
                    }
                }
            }

            
        }

        .grid-row:nth-child(odd){
            .grid-column{
                &.text{
                    background-color: $primary-client-color;
                    color: #212331;
                }
            }
            
        }
        .grid-row:nth-child(even){
            .grid-column{
                &.text{
                    background-color: $secondary-client-color;
                    color: #FFF;
                }
            }
        }
    }

    .module-2{
        img{
            max-width: 100%;
        }
    }

    .module-3{
        padding: 0 130px;

        .main-title{
            margin-top: 0;
            margin-bottom: 60px;
        }

        .main-title:first-child{
            margin-top: 40px;
            margin-bottom: 0;
        }

        

        @media (max-width: 1200px) {
            padding: 20px;
        }

        .main-title{
            color: #212331;
            text-align: center;
            font-family: Montserrat;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.08px;
        }

        .grid-row{
            @media (max-width: 1200px) {

                &:last-child{
                    flex-direction: column-reverse;
                }
            }

            .grid-column{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 !important;
                margin:0;

                .image-container{
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 100%;
                    min-height: 600px;

                    @media (max-width: 1200px) {
                        min-height: 400px;  /* Ajusta el valor según sea necesario para dispositivos medianos */
                    }
                
                    @media (max-width: 768px) {
                        min-height: 300px;  /* Ajusta el valor según sea necesario para dispositivos pequeños o móviles */
                    }
                
                    @media (max-width: 480px) {
                        min-height: 200px;  /* Ajusta el valor según sea necesario para dispositivos más pequeños */
                    }

                    
                }

                @media (max-width: 1200px) {
                    min-width: 100%;
                }

                img{
                    height: 100%;
                    max-width: 100%;
                }

                &.text{
                    font-family: Montserrat;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.08px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p{
                        padding: 0 60px;

                        .max{
                            display: inline;
                            font-weight: bold;
                            font-family: Montserrat;
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.08px;
                        }
                    }
                }

                
                .title{
                    color: #212331;
                    font-family: Montserrat;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.08px;
                    padding: 0 60px;    
                }

                p{
                    color: #212331;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.08px;
                    padding: 0 60px;
                }
            }

            
        }

        .grid-row:nth-child(odd){
            background-color: white;
            
        }
        .grid-row:nth-child(even){
            background-color: white;
        }
    }

    .module-4 {
        margin-top: 80px;
    
        .background {
          height: 405px;
          width: 100%;
          max-width: 100% !important;
          overflow: hidden;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          
          flex-direction: column;
          position: relative; /* Agregar posición relativa */
      
          .filter-layout {
            position: absolute;
            width: 100%;
            height: 100%; /* Establecer la altura al 100% del contenedor .background */
            opacity: 0.30;
            background: #000;
            z-index: 0;
          }
      
          .title {
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 50px;
            max-width: 100% !important;
            overflow: hidden;

            

            p:first-child {
                color: #FFF;
                font-family: Montserrat;
                font-size: 62px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.08px;
                margin-bottom: 0;

                @media (max-width: 960px){
                    font-size: 32px;
                    
                }
    
                @media (max-height: 450px){
                    font-size: 32px;
                }
      
                .small {
                    font-size: 52px;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.08px;

                    @media (max-width: 960px){
                        font-size: 22px;
                        
                    }
        
                    @media (max-height: 450px){
                        font-size: 22px;
                    }
                }
            }
      
            p:last-child {
                color: #FFF;
                font-family: Montserrat;
                font-size: 46px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.08px;
                opacity: 0.7;
                margin-top: 0;

                @media (max-width: 960px){
                    font-size: 22px;
                    
                }
    
                @media (max-height: 450px){
                    font-size: 22px;
                }
            }
          }
        }
    }

    .module-5{
        padding: 50px 0px 80px 0px;

        @media (max-width: 1200px) {
            padding: 50px 0;
        }

        .title{
            color: #212331;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.08px;
            margin-left: 43px;
            
        }

        .template1-list{
            list-style: none;

            @media (max-width: 1200px) {
                padding: 0 20px;
                margin: 0;
            }

            li:nth-child(even){
                margin-bottom: 10px;
            }

            .list-description{
                padding-left: 50px;
                color: #212331;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.08px;
            }
    
            .list-title{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
    
                .text{
                    color: #212331;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.08px;
                }
    
                .list-icon{
                    font-size:1.5em;
                    width: 25px;
                    height: 25px;
                    color: $primary-client-color;
                    background-color: white;
                    border-radius: 100%;
                    padding:5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    &:hover{
                        cursor: pointer;
                        background-color: $primary-client-color;
                        color: white;
                        transition: all .5s;
                    }
                }
            }
        }

        .login-container{
            background-color: white;
            border: solid 2px;
            border-color: #E1E3E3;
            padding: 10px;

            @media (max-width: 991px) {
                margin-top: 25px;
            }

            @media (min-width: 1200px) {
                width: 50%;
                margin-left: 25%;
            }

            @media (max-width: 500px) {
                width: 85%;
                margin-left: 5%;
            }

            h3{
                color: #212331;
                font-family: Montserrat;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.08px;
                text-align: center;
            }
        }
    }

    .module-6{
        .destinations,
        .services{
            width: 100%;
            .service-box{
                width: 25%;
                min-width: 25%;

                &:first-child{
                    background: $secondary-client-color;
                }

                &:last-child{
                    background-color: $primary-client-color;
                }

                &.title{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.08px;

                    @media (max-width: 1200px) {
                        font-size: 32px;
                    }
                }

                &.image{
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }


            }
        }
    }

    .module-7{
        background-color: $secondary-client-color;
        font-family: Montserrat;
        color: white;
        
        padding: 35px;
        .city{
            .city-name{

            }

            .hotel-list{
                list-style: none;
                padding-left: 0;
                .hotel-name{

                }
            }
        }
    }
      
}