.login-page{
    .login-page-content{
        background-size: cover;

        .form-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            background-color: #fff;
            height: calc(100vh - 70px - 55px);
            width: 50%;
            box-sizing: border-box;

            @media (max-width: 767px) {
                transition: all .2s;
                position: absolute;
                min-width: 90%;
                height: calc(50vh );
                margin-left: 5%;
                margin-top: calc((50vh - 70px - 55px) / 2);
                box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.25);
            }

            @media (min-width: 768px) and (max-width: 1023px) {
                min-width: 60%;
            }

            .welcome-title{
                color: #212331;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 2em;
            }

            .app-logo{
                max-width: 341px;
                margin-bottom: 11px;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            
          }
    }
    
}