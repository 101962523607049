@import '../../../styles/theme';
.news-featured-list-component {
    .ant-list-item {
        margin: 16px 0; // Márgenes superior e inferior
        padding: 0px ; // Espacio interior
        background-color: #f5f5f5; // Fondo gris claro
        border-radius: $app-border-radius ; // Bordes redondeados
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #e8e8e8; // Gris más oscuro al pasar el cursor
        }
    }

    .img-featured {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: $app-border-radius ; // Bordes redondeados solo arriba
        display:block; // lo forzamos para que no haya margenes debajo
    }


    .news-paragraph {
        color: #555; // Gris más claro para los párrafos secundarios
        font-size: 16px; // Tamaño de fuente estándar
    }
   


    .text-container {
        padding: 20px !important; // Espaciado interno
        display: flex;
        align-items: flex-start; // Alinea el contenido arriba
        justify-content: flex-start; // Alineación horizontal
        height: 100%; // Asegura que el contenedor tenga altura completa
   
    }

   
}
