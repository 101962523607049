@import '../../styles/theme';

.login-form{
    min-width: 50%;

    @media (max-width: 767px) {
        transition: all .2s;
        min-width: 70%;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        transition: all .2s;
        min-width: 70%;
    }

    .error-message{
        text-align: center;
        color: red;
        font-family: 'Montserrat';
        margin-bottom: 20px;
    }

    .reminder-password-link{
        width: 100%;
        text-align: right;
        margin-top: -20px;
        margin-bottom: 20px;
    }

    .btn-submit{
        width: 100%;
        margin-bottom: 20px;
        border: none !important;
    }

    .not-register{
        width: 100%;
        text-align: center;
        a{
            text-decoration: none;
        }
    }
}