@import '../../../styles/colors';
@import '../../../styles/theme';
.news-info-component {
    margin: 20px auto; // Centra el contenedor con margen vertical
    width: 85%; // Define un ancho máximo
    background-color: #fff; // Fondo blanco
    border-radius: $app-border-radius ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Sombra suave


    .news-content {
        text-align: left;
        padding: 0px !important;
    }
    .news-description {
        padding-left:25px;
        padding-right:25px;
        padding-bottom:25px;
    }


    .news-title{
        color: #262626;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        padding: 25px;
    }

    .main-image-container {
        width: 100%; // El contenedor ocupa todo el ancho disponible

        padding : 0px !important;
        object-fit: cover;
    
        .main-image {
            width: 100%; // La imagen ocupa todo el ancho del contenedor
            height: 250px;
           
           
            @media screen and (min-width: 1560px){
                height: 350px;
             
            }
            @media screen and (max-width: 1200){
                height: 350px;
             
            }

            @media screen and (max-width: 768px){
                object-fit: cover; // Recorta la imagen para ajustarse al contenedor
            }
        }
    }
    
    

    .news-details {
        padding-left:25px;
        padding-right:25px;
        font-size: 13px;
        color: #666;

    }

}

.back-link {
 
    cursor: pointer;
    display: inline-flex;
    font-size: 17px; // Mantiene el tamaño original del botó
    font-weight: 500;
    font-family: 'Montserrat';
    align-items: center;
    text-decoration: none;
    color: $primary-client-color;

    padding-left:30px;  
    padding-top: 10px;

    &:hover {
        text-decoration: underline;
        color: $secondary-client-color;
    }

    .back-button {
        margin-right: 8px;
    }
}
