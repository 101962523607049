.users-edit{
    .form-actions{
        text-align: right;

        button{
            width: 120px;
            margin-left: 10px;
            margin-bottom: 10px;

            &.transparent{
                background-color: transparent;
                border-color: #707070;;
                color: #707070;
            }
        }
    }
}