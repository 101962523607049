.waiting-email-sign-up{

    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: center;

    .info{

        color: #262626;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.2px;

        text-align: center;

        button{
            min-width: 250px;
            margin-top: 20px;
        }
    }
}